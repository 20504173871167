import React, { useEffect, useRef, useState } from "react";
import CommonTable from "../Common/CommonTable";
import CommonTd from "../../Tender/Common/CommonTd";
import { Link, useNavigate } from "react-router-dom";
import { getCsvFile, getPartyMasterList } from "../purchaseApi";
import { errorsMessage } from "../../constant";
import { FaRegEdit } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import usePurchaseURLFilters from "../Common/usePurchaseUrlFilter";
import BasicPagination from "../../Backoffice/Common/Pagination";

function VendorListPage() {
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);
  const [filters, setFilters] = usePurchaseURLFilters();
  const [partyMasterList, setPartyMasterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const [vendorFilter, setVendorFilter] = useState({
    msme_no: "",
    status: "",
    msme_type: "",
    search: "",
  });

  function editData(element) {
    navigate("/vendor-list/edit-vendor", { state: { editData: element } });
  }

  function getVendorData() {
    setLoading(true);
    getPartyMasterList({ ...vendorFilter, user_id, page })
      .then((res) => {
        setPartyMasterList(res?.data?.response?.PartyMasterList?.results?.data);
        setPageCount(res?.data?.response?.PartyMasterList?.results?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getDownloadVendorData() {
    getCsvFile({ ...vendorFilter, user_id })
      .then((res) => {
        const fileUrl = res?.data?.response?.venderCSVList?.file_url;

        if (fileUrl) {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = "Vendor_data.csv"; // Set the default file name
          document.body.appendChild(link);
          link.click(); // Simulate a click to start the download
          document.body.removeChild(link);
        } else {
          console.error("No file URL found in the response");
        }
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {});
  }

  const tableHeading = [
    {
      name: "SR No.",
      width: "5rem",
    },
    {
      name: "Party Code",
      width: "7rem",
    },
    {
      name: "Party Name ",
      width: "20rem",
    },
    {
      name: "Status ",
      width: "10rem",
    },
    {
      name: "Created By ",
      width: "14rem",
    },
    {
      name: "Contact Email ",
      width: "14rem",
    },
    {
      name: "Opening Balance ",
      width: "14rem",
    },
    {
      name: "Credit Limit Days ",
      width: "14rem",
    },
    {
      name: "PAN No. ",
      width: "14rem",
    },

    {
      name: "GST No. ",
      width: "14rem",
    },

    {
      name: "MSME No. ",
      width: "14rem",
    },
    {
      name: "MSME Type",
      width: "14rem",
    },
    {
      name: "Edit ",
      width: "3rem",
    },
  ];
  const filterHandleChange = (e) => {
    const { name, value } = e.target;
    setVendorFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const delay = setTimeout(() => {
      const newFilters = {
        ...filters,
        vendorFilter,
      };
      setFilters(newFilters);
      navigate(`?search=${newFilters.search}`);
      getVendorData();
    }, 1000);

    setTimer(delay);

    return () => clearTimeout(delay);
  }, [vendorFilter, page]);

  useEffect(() => {
    getVendorData();
  }, []);

  function handlePaginateChange(event, value) {
    setPage(value);
  }
  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-sm-2">
              <h4 className="page-title">VENDER LIST</h4>
            </div>
            <div className="col-sm-2 text-end">
              <select
                className="form-control"
                name="status"
                onChange={filterHandleChange}
                value={vendorFilter?.status}
              >
                <option value={""}>Select Status</option>
                <option value={"Active"}>Active</option>
                <option value={"NonActive"}>Non Active</option>
              </select>
            </div>
            <div className="col-sm-2 text-end">
              <select
                className="form-control"
                onChange={filterHandleChange}
                name="msme_type"
                value={vendorFilter?.msme_type}
              >
                <option value={""}>Select Msme Category</option>
                <option value={"Micro"}>Micro</option>
                <option value={"Small"}>Small</option>
                <option value={"Medium"}>Medium</option>
              </select>
            </div>
            <div className="col-sm-2 text-end">
              <input
                className="form-control "
                placeholder="SEARCH BY MSME No."
                onChange={filterHandleChange}
                name="msme_no"
                value={vendorFilter?.msme_no}
              />
            </div>
            <div className="col-sm-2 text-end">
              <div className="postion-relative">
                <input
                  className="form-control "
                  placeholder="SEARCH BY GSTIN AND PARTY NAME"
                  name="search"
                  onChange={filterHandleChange}
                  value={vendorFilter?.search}
                />
              </div>
            </div>
            <div className="col-sm-2 text-end">
              <div className="text-end">
                <Link to="/vendor-list/add-new-vendor">
                  <button className="btn btn-danger">Add New Vendor</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="text-end">
              <button
                className="btn btn-danger"
                onClick={getDownloadVendorData}
              >
                Download CSV
              </button>
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <div
                className="table-responsive table-container-scroll"
                style={{ overflowX: "auto" }}
              >
                <table
                  className="table common-table"
                  style={{ tableLayout: "unset" }}
                >
                  <CommonTable tableHeading={tableHeading}></CommonTable>
                  <tbody>
                    {partyMasterList.map((element) => (
                      <tr key={element.id}>
                        <CommonTd
                          width="4.96rem"
                          value={element?.id}
                        ></CommonTd>
                        <CommonTd
                          width="7rem"
                          value={element?.party_code}
                        ></CommonTd>
                        <CommonTd
                          width="20rem"
                          value={element?.party_name}
                        ></CommonTd>
                        <CommonTd
                          width="10rem"
                          value={element?.status}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.created_by}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.contact_email}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.opening_balance}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.credit_limit_days}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.pan_no}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.gst_no}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.msme_no}
                        ></CommonTd>
                        <CommonTd
                          width="14rem"
                          value={element?.msme_type}
                        ></CommonTd>
                        <td>
                          <p
                            style={{
                              minWidth: "3rem",
                              maxWidth: "3rem",
                              margin: "auto",
                              padding: "auto",
                              cursor: "pointer",
                            }}
                            className="text-center"
                          >
                            <FaRegEdit
                              className="text-dark font-20"
                              style={{ margin: "auto" }}
                              onClick={() => editData(element)}
                            />
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {loading && (
                    <div className="d-flex align-items-center justify-content-center pb-5">
                      <Spinner animation="grow" variant="danger" />
                      <Spinner animation="grow" variant="danger" />
                      <Spinner animation="grow" variant="danger" />
                    </div>
                  )}
                </table>
              </div>
              <div className="text-end mt-2">
                <BasicPagination
                  handlePaginateChange={handlePaginateChange}
                  page={page}
                  pageCount={pageCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorListPage;
